import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ element: Element, isAuthenticated, isAdminRequired, isAdmin, ...rest }) => {
  const location = useLocation();

  if (!isAuthenticated) {
    sessionStorage.setItem('lastVisited', location.pathname);
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (isAdminRequired && !isAdmin) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Element {...rest} />;
};

export default PrivateRoute;
